import React from 'react'

export const NoticeSection = ({ message, className }) => {
  if (!message) return null

  return (
    <div className={`notice-section ${className}`}>
      <i className='fa fa-exclamation-circle mr-2' />
      {message}
    </div>
  )
}
