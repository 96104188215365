import React, { Component, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
import map from 'lodash/map'
import { t, l } from 'i18n'
import { EparakstsSetupModal } from '../../utilityComponents/EparakstsSetupModal'
import {
  renderBlockStatusIcon, renderError, canEdit, refreshTooltips, openCollapse,
  signWithEparakstsEid, signWithEparakstsMobile, signWithSmartId, handleSmartIdResponse
} from './commonFunctions'
import SortableSignatures from './SortableSignatures'
import HintIcon from 'components/utilityComponents/HintIcon'
import Spinner from 'components/utilityComponents/Spinner'
import { objectToFormData } from '../../../files/helpers'

const AuarNumberBlock = ({
  auarInputDisabled, auarNumber, handleAuarNumberChange, auarNumberChanged, originalDocument,
  canEdit, destroySignedDocument, saveAuarNumber, projectItem
}) => {
  const renderAuarInput = () => (
    <div>
      <input type='text'
        className='form-control video-room-sidebar-item-input mb-4'
        placeholder={t('components.project.sidebar_steps.signing_block.auar_placeholder')}
        disabled={auarInputDisabled}
        value={auarNumber || ''}
        name='auar-number'
        onChange={handleAuarNumberChange}
      />
      {auarNumberChanged && renderAuarSaveButton()}
    </div>
  )

  const renderAuarSaveButton = () => (
    <button
      className='btn video-room-sidebar-btn d-block w-100 mb-3'
      data-action='save-auar-number'
      onClick={saveAuarNumber}
    >
      <i className='fa fa-save pointer mr-2'></i>
      {t('save')}
    </button>
  )

  const renderOriginalDocument = () => {
    if (originalDocument) {
      const originalDocumentUrl = `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents/${originalDocument.id}/download`

      return (
        <div className="video-room-sidebar-item-block-link-wrapper mb-1">
          <a href={originalDocumentUrl} target='_blank' className="video-room-sidebar-item-block-link">
            {originalDocument.name}
          </a>
          {canEdit && <i className='fa fa-times mr-auto ml-2 pointer' onClick={destroySignedDocument} />}
        </div>
      )
    }
  }

  return (
    <div className='video-room-sidebar-item-block dimmed'>
      <p className='video-room-sidebar-item-block-text'>
        {t('components.project.sidebar_steps.signing_block.client_document_upload')}
      </p>
      {renderAuarInput()}
      {renderOriginalDocument()}
    </div>
  )
}

const SmartIdCode = ({ code }) => (
  <div className='smart-id-code-wrapper'>
    <hr />
    <p className='mb-1'>{t('components.project.sidebar_steps.signing_block.smartid_confirmation_title')}:</p>
    <h5 className='mb-3 font-weight-bold'>{code}</h5>
    <p className='mb-0'>{t('components.project.sidebar_steps.signing_block.smartid_confirmation_hint')}</p>
  </div>
)

const SignerButtons = ({
  isNotary, currentUserId, projectItem, canEdit, originalDocument, latestDocument,
  uploadingInProgress, signWithSmartId, saveFile, auarNumberPersisted,
  signWithEparakstsEid, signWithEparakstsMobile
}) => {
  const isCurrentSigner = projectItem.currentDocumentSignerId == currentUserId

  const notaryCanUploadInitial = isNotary && !originalDocument && canEdit && auarNumberPersisted
  const userCanSign = !isNotary && isCurrentSigner && canEdit && latestDocument

  return (
    <Fragment>
      {userCanSign &&
        <Fragment>
          <p>{t('components.project.sidebar_steps.signing_block.choose_signing_method')}</p>
          <EparakstsEidButton signWithEparakstsEid={signWithEparakstsEid} latestDocument={latestDocument} />
          <EparakstsMobileButton signWithEparakstsMobile={signWithEparakstsMobile} latestDocument={latestDocument} />
          <SmartIdButton signWithSmartId={signWithSmartId} latestDocument={latestDocument} />
          <div className='text-center my-2'>{t('or')}</div>
          <SignedDocumentDownload projectItem={projectItem} latestDocument={latestDocument} />
        </Fragment>
      }
      {(notaryCanUploadInitial || userCanSign) &&
        <SignedDocumentUpload isNotary={isNotary} uploadingInProgress={uploadingInProgress} saveFile={saveFile} />
      }
    </Fragment>
  )
}

const EparakstsEidButton = ({ signWithEparakstsEid, latestDocument }) => (
  <span
    onClick={() => signWithEparakstsEid(latestDocument)}
    className='btn video-room-sidebar-btn d-block w-100 mb-2'
    id='sign-with-eparaksts-eid'
  >
    {t('components.project.sidebar_steps.signing_block.eparaksts_eid')}
  </span>
)

const EparakstsMobileButton = ({ signWithEparakstsMobile, latestDocument }) => (
  <span
    onClick={() => signWithEparakstsMobile(latestDocument)}
    className='btn video-room-sidebar-btn d-block w-100 mb-2'
    id='sign-with-eparaksts-mobile'
  >
    {t('components.project.sidebar_steps.signing_block.eparaksts_mobile')}
  </span>
)

const SmartIdButton = ({ signWithSmartId, latestDocument }) => (
  <span
    onClick={() => signWithSmartId(latestDocument)}
    className='btn video-room-sidebar-btn d-block w-100 mb-2'
    id='sign-with-smartid'
  >
    {t('components.project.sidebar_steps.signing_block.smartid')}
  </span>
)

const SignedDocumentDownload = ({ projectItem, latestDocument }) => {
  if (!latestDocument) return
  const url = `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents/${latestDocument.id}/download`

  return (
    <a href={url} target='_blank' className='btn video-room-sidebar-btn d-block w-100 mb-2' data-action='download'>
      <i className='fa fa-download mr-2'></i>
      {t('components.project.sidebar_steps.signing_block.download_signature')}
    </a>
  )
}

const SignedDocumentUpload = ({ isNotary, uploadingInProgress, saveFile }) => {
  const labelKey = isNotary ? 'upload' : 'upload_signed'

  if (uploadingInProgress) {
    return (
      <div>
        <label className='btn video-room-sidebar-btn d-block w-100 mb-2'>
          <span className='loading-indicator small' />
        </label>
      </div>
    )
  } else {
    return (
      <div>
        <label htmlFor='signed-document-upload' className='btn video-room-sidebar-btn d-block w-100 mb-2'>
          <i className='fa fa-upload mr-2'></i>
          {t(`components.project.sidebar_steps.signing_block.${labelKey}`)}
        </label>
        <input
          id='signed-document-upload'
          className='video-room-sidebar-item-file-input'
          type='file'
          onChange={saveFile}
        />
      </div>
    )
  }
}

class SigningBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showEparakstsSetupModal: false,
      eparakstsInProgress: false,
      error: null,
      auarNumber: props.projectItem.auarNumber, // Can stay in state, because other users don't need to see it
      auarNumberPersisted: !!props.projectItem.auarNumber,
      auarNumberChanged: false,
      uploadingInProgress: false,
      tooltipHidden: false,
      smartIdCode: null,
      collapseOpened: props.projectItem.signingBlockActive
    }

    this.signWithEparakstsEid = signWithEparakstsEid.bind(this)
    this.signWithEparakstsMobile = signWithEparakstsMobile.bind(this)
    this.signWithSmartId = signWithSmartId.bind(this)
    this.handleSmartIdResponse = handleSmartIdResponse.bind(this)
    this.renderBlockStatusIcon = renderBlockStatusIcon.bind(this)
    this.renderError = renderError.bind(this)
    this.refreshTooltips = refreshTooltips.bind(this)
    this.openCollapse = openCollapse.bind(this)
    this.canEdit = canEdit.bind(this)
    this.activeFlag = 'signingBlockActive'
  }

  componentWillReceiveProps(nextProps) {
    this.refreshTooltips(nextProps)
    if (this.props.isNotary) this.openCollapse(nextProps)
  }

  saveFile = (event) => {
    if(this.state.uploadingInProgress) { return }

    const { projectItem } = this.props
    const input = event.target
    const file = input.files[0]
    this.setState({ error: null, uploadingInProgress: true })

    let url = `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents`

    axios({
      url: url,
      method: 'POST',
      contentType: false,
      processData: false,
      data: objectToFormData({
        signed_document: {
          document: file,
          name: file.name
        }
      })
    }).
    then(({ data }) => {
      this.props.subscription.send({})
      this.setState({ uploadingInProgress: false })
      input.value = ''
    }).
    catch(({ response }) => {
      this.setState({
        error: response.data && response.data[0],
        uploadingInProgress: false
      })
    })
  }

  isSmartIdInProgress = () => {
    return !!this.state.smartIdCode
  }

  originalDocument() {
    return this.nonFinalDocuments()[0]
  }

  latestDocument() {
    const documents = this.nonFinalDocuments()
    return documents[documents.length - 1]
  }

  nonFinalDocuments() {
    return this.props.projectItem.signedDocuments.filter(sd => !sd.finalVersion)
  }

  isOriginalDocumentSigned = () => {
    const originalDocument = this.originalDocument()
    return originalDocument && originalDocument.signatures.length > 0
  }

  saveAuarNumber = () => {
    const { projectItem, subscription } = this.props

    axios.put(`/projects/${projectItem.projectId}/project_items/${projectItem.id}`, {
      project_item: { auar_number: this.state.auarNumber }
    }).
    then(({ data }) => {
      this.setState({ auarNumberChanged: false, auarNumberPersisted: !!data.auar_number, error: null })
      subscription.send({})
    }).
    catch(({ response }) => {
      this.setState({ error: response.data.auar_number[0] })
    })
  }

  cancelSigningStep = () => {
    const { projectItem, subscription } = this.props

    axios.put(`/projects/${projectItem.projectId}/project_items/${projectItem.id}/cancel_signing_step`).then(() => {
      subscription.send({})
    })
  }

  handleAuarNumberChange = (event) => {
    this.setState({ auarNumber: event.target.value, auarNumberChanged: true })
  }

  renderSignatures() {
    const { isNotary } = this.props
    const originalDocument = this.originalDocument()
    const latestDocument = this.latestDocument()
    const originalDocumentSignaturesCount = originalDocument && originalDocument.signatures.length

    return (
      <div>
        <ol className='signatures-list'>
          {originalDocument && map(originalDocument.orderedSigners, (signer, index) => {
            // Skip pre-signed original document signatures
            const userSignatureIndex = index + originalDocumentSignaturesCount

            let signerInfo = signer.fullName

            if (isNotary && signer && signer.formattedPersonalCode) {
              signerInfo += ` (${signer.formattedPersonalCode})`
            }

            return (
              <li key={index} className='signature'>
                <div className='signature-signer'>
                  {signerInfo}
                </div>
                <div className='signature-status'>
                  {this.renderSignatureStatus(latestDocument, signer, userSignatureIndex)}
                </div>
              </li>
            )
          })}
        </ol>
        {this.renderCancelSigningStepButton()}
      </div>
    )
  }

  renderSignatureStatus(latestDocument, signer, userSignatureIndex) {
    const { projectItem, currentUserId } = this.props
    const userSignature = latestDocument && latestDocument.signatures[userSignatureIndex]

    let translation

    if (userSignature) {
      const signatureValid = userSignature.isSignatureValid && userSignature.matchingPersonalCode

      let signatureStatus
      if (signatureValid) {
        signatureStatus = 'signature_valid'
      } else if (!userSignature.matchingPersonalCode) {
        signatureStatus = 'personal_code_mismatch'
      } else {
        signatureStatus = 'signature_invalid'
      }

      let translatedStatus = t(`activerecord.attributes.projects/signed_document.status_translations.${signatureStatus}`)
      if (!userSignature.matchingPersonalCode && this.props.isNotary) {
        translatedStatus += ` (${userSignature.socialSecurityNumber})`
      }

      return (
        <span>
          <HintIcon
            className='d-inline-block signature-status-icon'
            faIcon={signatureValid ? 'check' : 'exclamation-circle'}
          >
            <p>{translatedStatus}</p>
          </HintIcon>
          <span className='ml-1'>
            {t('components.project.sidebar_steps.signing_block.client_signed', {
              time: l('time.formats.default', userSignature.signedAt)
            })}
          </span>
        </span>
      )
    } else if (projectItem.currentDocumentSignerId === signer.id) {
      translation = signer.id === currentUserId ? 'your_signing_turn' : 'client_signing'
    } else {
      translation = 'client_waiting'
    }

    return t(`components.project.sidebar_steps.signing_block.${translation}`)
  }

  renderCancelSigningStepButton() {
    if (!this.props.isNotary || !this.canEdit() || this.props.projectUsers.length < 2) return

    return (
      <button type='button'
        className='btn video-room-sidebar-btn d-block w-100 mb-2'
        onClick={this.cancelSigningStep}
      >
        {t('cancel')}
      </button>
    )
  }

  renderSortableSignatures() {
    if (!this.props.isNotary) {
      return <div>{t('components.project.sidebar_steps.signing_block.document_processing')}</div>
    }

    const originalDocument = this.originalDocument()
    if (!originalDocument) return

    return (
      <div>
        <p className='video-room-sidebar-item-block-text'>
          {t('components.project.sidebar_steps.signing_block.signing_order')}
        </p>
        <SortableSignatures
          projectItem={this.props.projectItem}
          projectUsers={this.props.projectUsers}
          subscription={this.props.subscription}
          document={originalDocument}
          signatures={originalDocument.signatures}
        />
      </div>
    )
  }

  destroySignedDocument = () => {
    const { projectItem } = this.props
    axios.delete(
      `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents`
    ).then((resp) => {
      this.props.subscription.send({})
      this.setState({
        auarNumberChanged: false,
        auarNumberPersisted: !!resp.data.auar_number,
        auarNumber: resp.data.auar_number
      })
    }).catch(req => {})
  }

  render() {
    const {
      collapseOpened, smartIdCode, error, eparakstsInProgress, showEparakstsSetupModal,
      auarNumber, auarNumberChanged
    } = this.state

    const { projectItem, currentUserId, isNotary } = this.props

    const originalDocument = this.originalDocument()
    const latestDocument = this.latestDocument()
    const latestDocumentStatus = latestDocument && latestDocument.status

    return (
      <div className="video-room-sidebar-item-holder" data-contains='signing'>
        <div className="video-room-sidebar-item mb-3">
          <a data-toggle="collapse" href="#collapse2"
            className={`video-room-sidebar-item-toggle ${collapseOpened ? '' : 'collapsed'}`}
          >
            <span>{t('components.project.sidebar_steps.signing_block.title')}</span>
            <i className="fa fa-chevron-right ml-auto"></i>
          </a>

          <div className={`video-room-sidebar-item-content collapse ${collapseOpened ? 'show' : ''}`}
            id="collapse2"
          >
            {error && this.renderError()}
            {isNotary &&
              <AuarNumberBlock
                projectItem={projectItem}
                auarInputDisabled={!projectItem.signingBlockActive}
                auarNumber={auarNumber}
                handleAuarNumberChange={this.handleAuarNumberChange}
                auarNumberChanged={auarNumberChanged}
                originalDocument={this.originalDocument()}
                canEdit={this.canEdit()}
                destroySignedDocument={this.destroySignedDocument}
                saveAuarNumber={this.saveAuarNumber}
              />
            }
            <div className='video-room-sidebar-item-block'>
              {isNotary && this.isOriginalDocumentSigned() &&
                <HintIcon
                  className='original-document-signatures-warning d-block mb-3'
                  faIcon='exclamation-circle'
                  label={t('components.project.sidebar_steps.signing_block.original_document_contains_signatures')}
                >
                  {map(originalDocument.signatures, signature =>
                    <p className='text-nowrap' key={signature.id}>
                      {`${signature.signerName}, ${moment(signature.signedAt).format('DD.MM.YYYY. H:mm:ss Z')}`}
                    </p>
                  )}
                </HintIcon>
              }
              {latestDocumentStatus && latestDocumentStatus !== 'signature_valid' &&
                <p className='document-warning'>
                  <i className='fa fa-exclamation-circle mr-2 text-warning' />
                  <span>{t(`activerecord.attributes.projects/signed_document.status_translations.${latestDocument.status}`)}</span>
                </p>
              }
              {projectItem.signingInProgress ? this.renderSignatures() : this.renderSortableSignatures()}
              {eparakstsInProgress && <Spinner />}
              {this.isSmartIdInProgress() && <SmartIdCode code={smartIdCode} />}
              {!eparakstsInProgress && !this.isSmartIdInProgress() &&
                <SignerButtons
                  isNotary={isNotary}
                  currentUserId={currentUserId}
                  projectItem={projectItem}
                  canEdit={this.canEdit()}
                  originalDocument={originalDocument}
                  latestDocument={latestDocument}
                  uploadingInProgress={this.state.uploadingInProgress}
                  signWithEparakstsEid={this.signWithEparakstsEid}
                  signWithEparakstsMobile={this.signWithEparakstsMobile}
                  signWithSmartId={this.signWithSmartId}
                  saveFile={this.saveFile}
                  auarNumberPersisted={this.state.auarNumberPersisted}
                />
              }
            </div>
          </div>
        </div>
        {this.renderBlockStatusIcon()}
        <EparakstsSetupModal
          isOpen={showEparakstsSetupModal}
          onClose={() => this.setState({ showEparakstsSetupModal: false })}
        />
      </div>
    )
  }
}

export default SigningBlock
