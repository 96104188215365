import React from 'react'
import { t } from 'i18n'

const scope = 'components.inheritance_claims'

const Item = ({ name, value }) => {
  return (
    <dl className='row'>
      <dt className="col-sm-12 col-lg-6">{t(name, { scope })}</dt>
      <dd className="col-sm-12 col-lg-6">{value || '-'}</dd>
    </dl>
  )
}

const Matter = ({ matter }) => {
  return (
    <div className='service-form col-sm-8 col-md-6 col-lg-5 m-auto py-5'>
      <div className='inheritance-table-container d-block'>
        <div>
          <h6>{t('matter_no_title', { scope, number: matter.caseNumber })}</h6>
        </div>
        <table className='table services-table inheritance-checks-table bg-light-blue'>
          <tbody>
            <tr className='bg-light-blue'>
              <th>{t('inheritance_matter', { scope })}</th>
              <td>
                <Item name='sworn_notary' value={matter.notaryFullName} />
                <Item name='matter_no' value={matter.caseNumber} />
                <Item name='claim_deadline' value={matter.claimSubmissionDeadline} />
              </td>
            </tr>
            <tr>
              <th>{t('testator', { scope })}</th>
              <td>
              <Item name='testator_first_name' value={matter.testatorsFirstName} />
              <Item name='testator_last_name' value={matter.testatorsLastName} />
              <Item name='testator_date_of_birth' value={matter.testatorsBirthDate} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const MatterResults = ({ matters, setSelectedMatter, backToSearch }) => {
  return (
    <div>
      {matters.map(matter =>
        <Matter key={matter.matterId} matter={matter} />
      )}

      <div className='text-center mb-4'>
        <button
            className='btn form-submit-button secondary mr-4'
            onClick={backToSearch}
          >
            {t('back_to_search')}
          </button>
        <button
          className='btn form-submit-button'
          onClick={() => setSelectedMatter(matters[0])}
        >
          {t('continue', { scope })}
        </button>
      </div>
    </div>
  )
}

export { MatterResults }
