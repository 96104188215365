import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { t } from 'i18n'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { NoticeSection } from './shared'
import InputWrapper from '../utilityComponents/InputWrapper'

const scope = 'components.inheritance_claims'

const MatterSearch = ({ setMatters }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [noPersonalCode, setNoPersonalCode] = useState(false)
  const [matterSearchFields, setmatterSearchFields] = useState({
    testatorPersonalCode: '',
    testatorFirstName: '',
    testatorLastName: '',
    testatorDateOfBirth: null
  })

  useEffect(() => {
    clearErrors()
  }, [noPersonalCode])

  const handleChange = (e) => {
    const { name, value } = e.target

    setmatterSearchFields({
      ...matterSearchFields,
      [name]: value
    })
  }

  const handleDateChange = (field, date) => {
    setmatterSearchFields({
      ...matterSearchFields,
      [field]: date
    })
  }

  const clearErrors = () => {
    setErrors({})
  }

  const formatSearchFields = () => {
    let searchParams = { no_personal_code: noPersonalCode }

    if (noPersonalCode) {
      return {
        ...searchParams,
        testator_first_name: matterSearchFields.testatorFirstName,
        testator_last_name: matterSearchFields.testatorLastName,
        testator_date_of_birth: matterSearchFields.testatorDateOfBirth
      }
    } else {
      return {
        ...searchParams,
        testator_personal_code: matterSearchFields.testatorPersonalCode
      }
    }
  }

  const notFoundErrorHtml = () => (
    <span>
      {t('inheritance_matter_not_started', { scope })}
      {' - '}
      <a href='/notaries_map'>{t('search_notary_link_text', { scope })}</a>
    </span>
  )

  const onSubmit = () => {
    clearErrors()
    setLoading(true)

    const data = formatSearchFields()

    axios.post('/inheritance_claims/search_inheritance_matter', data)
    .then(({ data }) => {
      setMatters(data)
    }).catch(({ response }) => {
      if (response.status === 404) {
        setErrors({ base: notFoundErrorHtml() })
      } else {
        setErrors(response.data)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className='service-form col-sm-8 col-md-6 col-lg-5 m-auto py-5'>
      <NoticeSection message={errors.base} className='danger' />

      <InputWrapper errorArray={errors.testator_personal_code}>
        <label className='control-label'>{t('testator_personal_code', { scope })}</label>
        <InputMask
          type='text'
          className='form-control'
          name='testatorPersonalCode'
          placeholder={noPersonalCode
            ? t('no_personal_code', { scope })
            : t('prompts.testator_personal_code', { scope })
          }
          value={noPersonalCode ? '' : matterSearchFields.testatorPersonalCode}
          mask={noPersonalCode ? null : '999999-99999'}
          onChange={handleChange}
          disabled={noPersonalCode}
        />
      </InputWrapper>

      <InputWrapper>
        <label className='control-label'>
          <input
            type='checkbox'
            className='mr-2'
            checked={noPersonalCode}
            onChange={() => setNoPersonalCode(!noPersonalCode)}
          />
          <span>{t('no_personal_code', { scope })}</span>
        </label>
      </InputWrapper>

      {noPersonalCode &&
        <>
          <div className='row'>
            <div className='col-md-6'>
              <InputWrapper errorArray={errors.testator_first_name}>
                <label className='control-label'>{t('testator_first_name', { scope })}</label>
                <input
                  type='text'
                  className='form-control'
                  name='testatorFirstName'
                  placeholder={t('prompts.testator_first_name', { scope })}
                  value={matterSearchFields.testatorFirstName}
                  onChange={handleChange}
                />
              </InputWrapper>
            </div>

            <div className='col-md-6'>
              <InputWrapper errorArray={errors.testator_last_name}>
                <label className='control-label'>{t('testator_last_name', { scope })}</label>
                <input
                  type='text'
                  className='form-control'
                  name='testatorLastName'
                  placeholder={t('prompts.testator_last_name', { scope })}
                  value={matterSearchFields.testatorLastName}
                  onChange={handleChange}
                />
              </InputWrapper>
            </div>
          </div>

          <InputWrapper errorArray={errors.testator_date_of_birth}>
            <label className='control-label'>{t('testator_date_of_birth', { scope })}</label>
            <DatePicker
              className='form-control'
              name='testatorDateOfBirth'
              placeholderText={t('prompts.testator_date_of_birth', { scope })}
              selected={matterSearchFields.testatorDateOfBirth}
              onChange={date => handleDateChange('testatorDateOfBirth', date)}
              autoComplete='off'
              showMonthDropdown
              showYearDropdown
              maxDate={moment()}
            />
          </InputWrapper>
        </>
      }
      <div className='text-center'>
        <button
          type='button'
          className='btn form-submit-button'
          onClick={onSubmit}
          disabled={loading}
        >
          {t('search_matter', { scope })}
        </button>
      </div>
    </div>
  )
}

export { MatterSearch }
