import React, { useState } from 'react'
import { MatterSearch } from './MatterSearch'
import { MatterResults } from './MatterResults'
import { ClaimForm } from './ClaimForm'

const scope = 'components.inheritance_claims'

const InheritanceClaimForm = ({ newInheritanceClaimTemplate }) => {
  const [matters, setMatters] = useState([])
  const [selectedMatter, setSelectedMatter] = useState(null)

  if (matters.length === 0) {
    return <MatterSearch setMatters={setMatters} />
  } else if (!selectedMatter) {
    return (
      <MatterResults
        matters={matters}
        setSelectedMatter={setSelectedMatter}
        backToSearch={() => setMatters([])}
      />
    )
  } else if (selectedMatter) {
    return (
      <ClaimForm
        newInheritanceClaimTemplate={{
          ...newInheritanceClaimTemplate,
          matterId: selectedMatter.matterId
        }}
      />
    )
  }
}

export { InheritanceClaimForm }
