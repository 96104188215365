import { t, locale } from 'i18n'
import axios from 'axios'

export const performEparakstsEidSigning = async ({
  initializeSigningUrl,
  finalizeSigningUrl,
  onStart,
  onError,
  onSuccess,
  onMissingPlugin
}) => {
  const handleEidCardError = (e) => {
    onError(t(`components.project.sidebar_steps.signing_block.eparaksts_statuses.${e.message}`, { default: e.message }))
  }

  onStart()
  window.eParakstsTokenSigning() // Declare signing functions

  const getVersionTimer = new Promise((resolve, reject) => {
    setTimeout(reject, 1000 * 15) // 15 seconds
  })

  // getVersion promise never completes if plugin is missing
  const version = await Promise.race(
    [window.getVersion(), getVersionTimer]
  ).catch(() => {
    // Could not get eparaksts extension version
    onMissingPlugin()
  })

  if (!version) return

  const signCert = await window.getCertificate(
    { lang: locale, operation: 'sign' }
  ).catch(handleEidCardError)

  if (!signCert) return

  const { data: { digest_hex } } = await axios.post(initializeSigningUrl, {
    sign_cert_hex: signCert.hex
  }).catch(({ response }) => {
    onError(response.data)
  })

  if (!digest_hex) return

  const signature = await window.sign(
    signCert,
    { type: 'SHA-256', hex: digest_hex },
    { lang: locale, operation: 'sign' }
  ).catch(handleEidCardError)

  if (!signature) return

  const authCert = await window.getCertificate(
    { lang: locale, operation: 'auth' }
  ).catch(handleEidCardError)

  if (!authCert) return

  axios.post(finalizeSigningUrl, {
    signature_hex: signature.hex,
    auth_cert_hex: authCert.hex
  }).then(({ data }) => {
    onSuccess(data)
  }).catch(({ response }) => {
    onError(response.data)
  })
}
