import React from 'react'
import { t } from 'i18n'
import axios from 'axios'
import { performEparakstsEidSigning } from '../../utilityComponents/EparakstsFunctions'
import Tooltip from '../../utilityComponents/Tooltip'
import cableConsumer from '../../../files/cableConsumer'

const renderBlockStatusIcon = function() {
  const { projectItem, isNotary } = this.props

  const callback = () => {
    const { onToggleActive } = this.props

    if (isNotary && !projectItem.closedAt) {
      onToggleActive(this.activeFlag)
    }
  }

  let classNames = 'fa fa-lg '
  let localeEnding
  const localePrefix = 'components.project.sidebar_steps'

  if (projectItem[this.activeFlag]) {
    classNames += 'fa-circle '
    localeEnding = isNotary ? '.deactivate' : '.active'
  } else {
    localeEnding = isNotary ? '.activate' : '.inactive'
    classNames += 'fa-check '
  }

  if (projectItem.closedAt || projectItem[this.activeFlag] == null) {
    classNames += 'dimmed'
  } else if (isNotary) {
    classNames += 'pointer'
  }

  if (this.state && this.state.tooltipHidden) {
    return (
      <div className='tooltip-wrapper'>
        <i className={classNames} onClick={callback} data-action='toggle'></i>
      </div>
    )
  }

  return (
    <Tooltip tooltipText={t(localePrefix + localeEnding)}>
      <i className={classNames} onClick={callback} data-action='toggle'></i>
    </Tooltip>
  )
}

const renderError = function() {
  return (
    <div className='video-room-sidebar-item-note red d-flex justify-content-between align-items-center'>
      <span>{this.state.error}</span>
      <i className='fa fa-times pointer' onClick={() => this.setState({ error: null })} />
    </div>
  )
}

const refreshTooltips = function(nextProps) {
   this.setState({ tooltipHidden: true })

   setTimeout(() => this.setState({ tooltipHidden: false }), 400)
}

const openCollapse = function(nextProps) {
  const { projectItem } = this.props

  if (this.props && (projectItem[this.activeFlag] !== nextProps.projectItem[this.activeFlag])) {
    this.setState({ collapseOpened: nextProps.projectItem[this.activeFlag] })
  }
}

const canEdit = function() {
  const { projectItem } = this.props

  return projectItem[this.activeFlag] && !projectItem.closedAt
}

const signWithEparakstsEid = async function(latestDocument) {
  const { project, projectItem } = this.props
  const signedDocumentBaseUrl = `/projects/${project.id}/project_items/${projectItem.id}/signed_documents/${latestDocument.id}`

  const onEparakstsEidStart = () => {
    this.setState({ eparakstsInProgress: true, error: null })
  }

  const onEparakstsEidError = (error) => {
    this.setState({ eparakstsInProgress: false, error })
  }

  const onEparakstsEidSuccess = () => {
    this.setState({ eparakstsInProgress: false })
  }

  const onEparakstsMissingPlugin = () => {
    this.setState({ eparakstsInProgress: false, showEparakstsSetupModal: true })
  }

  performEparakstsEidSigning({
    initializeSigningUrl: `${signedDocumentBaseUrl}/initialize_eid_signing`,
    finalizeSigningUrl: `${signedDocumentBaseUrl}/finalize_eid_signing`,
    onStart: onEparakstsEidStart,
    onError: onEparakstsEidError,
    onSuccess: onEparakstsEidSuccess,
    onMissingPlugin: onEparakstsMissingPlugin
  })
}

const signWithEparakstsMobile = function(latestDocument) {
  this.setState({ error: null, eparakstsInProgress: true })

  const { cableConsumer, project, projectItem } = this.props

  this.signingStatusSubscription = cableConsumer.subscriptions.create(
    {
      channel: 'EparakstsResponseChannel',
      resource_type: 'Projects::SignedDocument',
      resource_id: latestDocument.id
    },
    {
      received: ({ success, message }) => {
        this.setState({ eparakstsInProgress: false })

        if (!success) {
          this.setState({ error: message })
        }
      }
    }
  )

  window.open(
    `/projects/${project.id}/project_items/${projectItem.id}/signed_documents/${latestDocument.id}/sign_with_eparaksts_mobile`,
    '',
    `width=${screen.width},height=${screen.height}`
  )
}

const signWithSmartId = function(latestDocument) {
  const { project, projectItem } = this.props
  this.setState({ error: null })

  axios.post(
    `/projects/${project.id}/project_items/${projectItem.id}/signed_documents/${latestDocument.id}/sign_with_smartid`
  ).then(({ data }) => {
    this.setState({ smartIdCode: data.code })

    this.smartIdSignerResponseSubscription = cableConsumer.subscriptions.create(
      {
        channel: 'SmartIdResponseChannel',
        resource_type: 'Projects::SignedDocument',
        resource_id: latestDocument.id
      },
      {
        received: this.handleSmartIdResponse
      }
    )
  }).catch(({ response }) => {
    const error = t(`components.project.sidebar_steps.signing_block.smartid_statuses.${response.data.error}`, { default: response.error })
    this.setState({ error })
  })
}

const handleSmartIdResponse = function(response) {
  if (response.document_signed) {
    this.setState({ smartIdCode: null, error: null })
  } else {
    this.setState({
      smartIdCode: null,
      error: t(`components.project.sidebar_steps.signing_block.smartid_statuses.${response.error}`, { default: response.error })
    })
  }

  this.smartIdSignerResponseSubscription.unsubscribe()
}

export {
  renderBlockStatusIcon, renderError, canEdit, refreshTooltips, openCollapse,
  signWithEparakstsEid, signWithEparakstsMobile, signWithSmartId, handleSmartIdResponse
}
