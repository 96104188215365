import React from 'react'
import { t, l } from 'i18n'
import moment from 'moment'

const scope = 'components.inheritance_claims'

const STATUS_PTN = 'PTN'

const Row = ({ inheritanceClaim }) => {
  const isPtn = inheritanceClaim.status === STATUS_PTN

  const renderCreditorFields = () => {
    if (inheritanceClaim.creditorType === 'natural_person') {
      return <>
        <div>{`${inheritanceClaim.creditorsFirstName} ${inheritanceClaim.creditorsLastName}`}</div>
        <div>{inheritanceClaim.creditorsPersonalCode}</div>
        <div>{inheritanceClaim.creditorsResidentialAddress}</div>
      </>
    } else if (inheritanceClaim.creditorType === 'legal_person') {
      return <>
        <div>{inheritanceClaim.creditorsLegalName}</div>
        <div>{inheritanceClaim.creditorsRegistrationNumber}</div>
        <div>{inheritanceClaim.creditorsLegalAddress}</div>
      </>
    }
  }

  const formatDisplayDate = (date) => {
    if (!date) return '-'

    return moment(date).format('DD.MM.YYYY.')
  }

  const renderPtnLink = () => {
    if (!isPtn) return '-'

    return (
      <a href={`/inheritance_claims/${inheritanceClaim.id}/view_ptn`} target='_blank'>
        {t('view')}
      </a>
    )
  }

  return (
    <tr>
      <td data-field='matter_number'>{inheritanceClaim.matterNumber}</td>
      <td data-field='creditor'>{renderCreditorFields()}</td>
      <td data-field='claim_type'>{inheritanceClaim.claimTypeName}</td>
      <td data-field='claim_justification'>{inheritanceClaim.claimJustificationName}</td>
      <td data-field='claim_amount'>{`${inheritanceClaim.claimAmount} EUR`}</td>
      <td data-field='status'>{inheritanceClaim.statusName}</td>
      <td data-field='ptn'>{renderPtnLink()}</td>
      <td data-field='correction_deadline'>{formatDisplayDate(inheritanceClaim.deficienciesEliminationDate)}</td>
      <td className='text-nowrap'>
        <div>
          <a href={`/inheritance_claims/${inheritanceClaim.id}`}>
            {t('view', { scope })}
          </a>
        </div>
        <div>
          <a
            href={`/inheritance_claims/${inheritanceClaim.id}/cancel`}
            data-method='post'
            data-confirm={t('are_you_sure')}
          >
            {t('cancel', { scope })}
          </a>
        </div>
        {isPtn &&
          <div>
            <a href={`/inheritance_claims/${inheritanceClaim.id}/edit`}>
              {t('submit_correction', { scope })}
            </a>
          </div>
        }
        <div>
          <a href={`/inheritance_claims/new?copy_inheritance_claim_id=${inheritanceClaim.id}`}>
            {t('copy', { scope })}
          </a>
        </div>
      </td>
    </tr>
  )
}

const InheritanceClaimsTable = ({ inheritanceClaims }) => {
  return (
    <table className='table services-table table-mobile-collapse'>
      <thead>
        <tr>
          <th className='shrink-col'>{t('matter_no', { scope })}</th>
          <th>{t('creditor', { scope })}</th>
          <th>{t('claim_type', { scope })}</th>
          <th>{t('claim_justification', { scope })}</th>
          <th>{t('claim_amount_short', { scope })}</th>
          <th>{t('status', { scope })}</th>
          <th>{t('ptn', { scope })}</th>
          <th className='shrink-col'>{t('correction_deadline', { scope })}</th>
          <th className='shrink-col'>{t('actions', { scope })}</th>
        </tr>
      </thead>
      <tbody>
        {inheritanceClaims.map(inheritanceClaim =>
          <Row key={inheritanceClaim.id} inheritanceClaim={inheritanceClaim} />
        )}
      </tbody>
    </table>
  )
}

export { InheritanceClaimsTable }
