import React, { Component, Fragment } from 'react'
import axios from 'axios'
import map from 'lodash/map'
import { t, l } from 'i18n'
import { EparakstsSetupModal } from '../../utilityComponents/EparakstsSetupModal'
import {
  renderBlockStatusIcon, renderError, canEdit, refreshTooltips, openCollapse,
  signWithEparakstsMobile, signWithEparakstsEid, signWithSmartId, handleSmartIdResponse
} from './commonFunctions'
import { objectToFormData } from '../../../files/helpers'
import HintIcon from 'components/utilityComponents/HintIcon'
import Spinner from 'components/utilityComponents/Spinner'

const statusIconMap = {
  signature_valid: 'check',
  document_changed: 'exclamation-circle',
  signature_invalid: 'exclamation-circle',
  invalid_request: 'exclamation-circle'
}

const SmartIdCode = ({ code }) => {
  return (
    <div className='smart-id-code-wrapper'>
      <hr />
      <p className='mb-1'>{t('components.project.sidebar_steps.signing_block.smartid_confirmation_title')}:</p>
      <h5 className='mb-3 font-weight-bold'>{code}</h5>
      <p className='mb-0'>{t('components.project.sidebar_steps.signing_block.smartid_confirmation_hint')}</p>
    </div>
  )
}

const SignerButtons = ({
  isNotary, projectItem, canEdit, latestDocument, uploadingInProgress, signWithSmartId, saveFile,
  signWithEparakstsEid, signWithEparakstsMobile
}) => {
  const notaryCanSign = isNotary && latestDocument && projectItem.signingInProgress && !projectItem.currentDocumentSignerId

  if (!notaryCanSign) return <p>{t('components.project.sidebar_steps.final_document_block.awaiting_client')}</p>

  return (
    <Fragment>
      <p>{t('components.project.sidebar_steps.signing_block.choose_signing_method')}</p>
      <EparakstsEidButton signWithEparakstsEid={signWithEparakstsEid} latestDocument={latestDocument} />
      <EparakstsMobileButton signWithEparakstsMobile={signWithEparakstsMobile} latestDocument={latestDocument} />
      <SmartIdButton signWithSmartId={signWithSmartId} latestDocument={latestDocument} />
      <div className='text-center my-2'>{t('or')}</div>
      <SignedDocumentDownload projectItem={projectItem} latestDocument={latestDocument} />
      <SignedDocumentUpload isNotary={isNotary} uploadingInProgress={uploadingInProgress} saveFile={saveFile} />
    </Fragment>
  )
}

const EparakstsEidButton = ({ signWithEparakstsEid, latestDocument }) => {
  return (
    <Fragment>
      <span
        onClick={() => signWithEparakstsEid(latestDocument)}
        className='btn video-room-sidebar-btn d-block w-100 mb-2'
        id='sign-with-eparaksts-eid'
      >
        {t('components.project.sidebar_steps.signing_block.eparaksts_eid')}
      </span>
    </Fragment>
  )
}

const EparakstsMobileButton = ({ signWithEparakstsMobile, latestDocument }) => {
  return (
    <Fragment>
      <span
        onClick={() => signWithEparakstsMobile(latestDocument)}
        className='btn video-room-sidebar-btn d-block w-100 mb-2'
        id='sign-with-eparaksts-mobile'
      >
        {t('components.project.sidebar_steps.signing_block.eparaksts_mobile')}
      </span>
    </Fragment>
  )
}

const SmartIdButton = ({ signWithSmartId, latestDocument }) => {
  return (
    <Fragment>
      <span
        onClick={() => signWithSmartId(latestDocument)}
        className='btn video-room-sidebar-btn d-block w-100 mb-2'
        id='sign-with-smartid'
      >
        {t('components.project.sidebar_steps.signing_block.smartid')}
      </span>
    </Fragment>
  )
}

const SignedDocumentDownload = ({ projectItem, latestDocument }) => {
  const url = latestDocument ? `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents/${latestDocument.id}/download` : null

  if (url) {
    return (
      <a href={url} target='_blank' className='btn video-room-sidebar-btn d-block w-100 mb-2' data-action='download'>
        <i className='fa fa-download mr-2'></i>
        {t('components.project.sidebar_steps.signing_block.download_signature')}
      </a>
    )
  }
}

const SignedDocumentUpload = ({ isNotary, uploadingInProgress, saveFile }) => {
  if (uploadingInProgress) {
    return (
      <div>
        <label className='btn video-room-sidebar-btn d-block w-100 mb-2'>
          <span className='loading-indicator small' />
        </label>
      </div>
    )
  } else {
    return (
      <div>
        <label htmlFor='final-document-upload' className='btn video-room-sidebar-btn d-block w-100 mb-2'>
          <i className='fa fa-upload mr-2'></i>
          {t(`components.project.sidebar_steps.final_document_block.upload_signed`)}
        </label>
        <input
          id='final-document-upload'
          className='video-room-sidebar-item-file-input'
          type='file'
          onChange={saveFile}
        />
      </div>
    )
  }
}

const SendToClientButton = ({ projectItem, document, subscription, setError }) => {
  const markAsSentToClient = () => {
    axios.post(`/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents/${document.id}/mark_as_sent_to_client`).then(() => {
      subscription.send({})
    }).catch(({ response: { data } }) => {
      setError(data.join(', '))
    })
  }

  if (document.isSentToClient) {
    return (
      <span className='opaque'>
        <i className='fa fa-check mr-2'></i>
        {t('components.project.sidebar_steps.final_document_block.visible_to_client')}
      </span>
    )
  } else {
    return (
      <a
        className='btn video-room-sidebar-btn d-block w-100 mb-3'
        data-action='send_to_client'
        onClick={markAsSentToClient}
      >
        <span>{t('components.project.sidebar_steps.final_document_block.send_to_client')}</span>
        <i className='fa fa-chevron-right text-white ml-2' />
      </a>
    )
  }
}

class FinalDocumentBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEparakstsSetupModal: false,
      eparakstsInProgress: false,
      uploadingInProgress: false,
      error: null,
      warnings: [],
      tooltipHidden: false,
      collapseOpened: props.projectItem.projectDocumentBlockActive,
    }

    this.signWithEparakstsMobile = signWithEparakstsMobile.bind(this)
    this.signWithEparakstsEid = signWithEparakstsEid.bind(this)
    this.signWithSmartId = signWithSmartId.bind(this)
    this.handleSmartIdResponse = handleSmartIdResponse.bind(this)
    this.renderBlockStatusIcon = renderBlockStatusIcon.bind(this)
    this.renderError = renderError.bind(this)
    this.refreshTooltips = refreshTooltips.bind(this)
    this.openCollapse = openCollapse.bind(this)
    this.canEdit = canEdit.bind(this)
    this.activeFlag = 'projectDocumentBlockActive'
  }

  componentWillReceiveProps(nextProps) {
    this.refreshTooltips(nextProps)
    if (this.props.isNotary) this.openCollapse(nextProps)
  }

  setError = (error) => {
    this.setState({ error })
  }

  isSmartIdInProgress = () => {
    return !!this.state.smartIdCode
  }

  originalDocument() {
    return this.nonFinalDocuments()[0]
  }

  latestDocument() {
    const documents = this.nonFinalDocuments()
    return documents[documents.length - 1]
  }

  nonFinalDocuments() {
    return this.props.projectItem.signedDocuments.filter(sd => !sd.finalVersion)
  }

  finalDocumentSignatures() {
    const { finalDocument } = this.props
    if (!finalDocument) return []

    const originalDocument = this.originalDocument()
    const originalDocumentSignaturesCount = originalDocument && originalDocument.signatures.length

    let finalDocumentSignatures = [...finalDocument.signatures]
    finalDocumentSignatures.splice(0, originalDocumentSignaturesCount)

    return finalDocumentSignatures
  }

  saveFile = (event) => {
    if(this.state.uploadingInProgress) { return }

    const { projectItem } = this.props
    const input = event.target
    const file = input.files[0]
    this.setState({ error: null, uploadingInProgress: true })

    axios({
      url: `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents`,
      method: 'POST',
      contentType: false,
      processData: false,
      data: objectToFormData({
        signed_document: {
          document: file,
          name: file.name,
          final_version: true
        }
      })
    }).
    then(response => {
      this.props.subscription.send({})
      this.setState({ uploadingInProgress: false, warnings: (response && response.data && response.data.warnings) || [] })
      input.value = ''
    }).
    catch(({ response }) => {
      this.setState({
        error: response && response.data && response.data[0],
        uploadingInProgress: false
      })
    })
  }

  renderSignedDocumentUpload() {
    const { projectItem, isNotary, finalDocument, subscription } = this.props
    const { smartIdCode, uploadingInProgress, eparakstsInProgress } = this.state

    if (finalDocument) {
      return (
        <SendToClientButton
          projectItem={projectItem}
          document={finalDocument}
          subscription={subscription}
          setError={this.setError}
        />
      )
    } else if (eparakstsInProgress) {
      return <Spinner />
    } else if (this.isSmartIdInProgress()) {
      return <SmartIdCode code={smartIdCode} />
    } else {
      return <SignerButtons
        isNotary={isNotary}
        projectItem={projectItem}
        canEdit={this.canEdit()}
        latestDocument={this.latestDocument()}
        uploadingInProgress={uploadingInProgress}
        signWithEparakstsMobile={this.signWithEparakstsMobile}
        signWithEparakstsEid={this.signWithEparakstsEid}
        signWithSmartId={this.signWithSmartId}
        saveFile={this.saveFile}
      />
    }
  }

  renderFinalDocumentLink() {
    const { finalDocument, projectItem } = this.props
    if (!finalDocument) return

    const finalDocumentUrl = `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents/${finalDocument.id}/download`

    return (
      <a href={finalDocumentUrl} target='_blank' className="video-room-sidebar-item-block-link">
        {finalDocument.name}
      </a>
    )
  }

  destroyFinalDocument = () => {
    const { projectItem } = this.props

    axios.delete(`/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents`, {
      data: { final_version: true }
    }).then(
      () => this.props.subscription.send({})
    ).catch(() => {})
  }

  renderDeleteButton() {
    if (this.props.isNotary && this.props.finalDocument) {
      return (
        <i className="fa fa-times mr-auto ml-2 pointer" onClick={this.destroyFinalDocument}></i>
      )
    }
  }

  renderSignature(signature) {
    const signatureStatus = signature.status == 1 ? 'signature_valid' : 'signature_invalid'
    return (
      <div className='signature pl-0' key={signature.id}>
        <div className='signature-signer'>
          {signature.signerName}
        </div>
        <div className='signature-status'>
          <span>
            <HintIcon className='d-inline-block' faIcon={statusIconMap[signatureStatus]}>
              <p>{t(`activerecord.attributes.projects/signed_document.status_translations.${signatureStatus}`)}</p>
            </HintIcon>
            <span className='ml-1'>
              {t('components.project.sidebar_steps.signing_block.client_signed', { time: l('time.formats.default', signature.signedAt) })}
            </span>
          </span>
        </div>
      </div>
    )
  }

  renderWarnings() {
    if (!this.props.isNotary) return

    return(
      <div className='video-room-sidebar-item-note orange'>
        {this.state.warnings.map((warning, index) => {
          return <div key={index}>{warning}</div>
        })}
      </div>
    )
  }

  renderClientContent(finalDocumentSignatures) {
    const { finalDocument, projectItem } = this.props

    if (finalDocument) {
      const downloadUrl = `/projects/${projectItem.projectId}/project_items/${projectItem.id}/signed_documents/${finalDocument.id}/download`

      return (
        <div className="video-room-sidebar-item-block">
          <a href={downloadUrl} target='_blank' className="video-room-sidebar-item-block-link">
            {finalDocument.name}
          </a>
          <div className='signatures-list pl-0 mb-3'>
            {map(finalDocumentSignatures, this.renderSignature)}
          </div>
          <a href={downloadUrl} target='_blank' className="btn video-room-sidebar-btn d-block w-100 mb-3" data-action='download'>
            <i className="fa fa-download mr-2"></i>
            {t('components.video_room.download')}
          </a>
        </div>
      )
    } else {
      return (
        <div className="video-room-sidebar-item-block">
          {t('components.project.sidebar_steps.final_document_block.document_in_process')}
        </div>
      )
    }
  }

  renderNotaryContent(finalDocumentSignatures) {
    return (
      <div className="video-room-sidebar-item-block">
        <p className="video-room-sidebar-item-block-text">
          {t('components.project.sidebar_steps.final_document_block.documents_section_title')}
        </p>
        <div className="video-room-sidebar-item-block-link-wrapper mb-3">
          {this.renderFinalDocumentLink()}
          {this.renderDeleteButton()}
        </div>
        <div className='signatures-list pl-0 mb-3'>
          {map(finalDocumentSignatures, this.renderSignature)}
        </div>

        {this.canEdit() && this.renderSignedDocumentUpload()}
      </div>
    )
  }

  render() {
    const { collapseOpened, showEparakstsSetupModal } = this.state
    const finalDocumentSignatures = this.finalDocumentSignatures()

    return (
      <div className="video-room-sidebar-item-holder" data-contains='final-document'>
        <div className="video-room-sidebar-item mb-3">
          <a data-toggle="collapse" href="#collapse4" className={`video-room-sidebar-item-toggle ${collapseOpened ? '' : 'collapsed'}`}>
            <span>
              {t('components.project.sidebar_steps.final_document_block.title')}
            </span>
            <i className="fa fa-chevron-right ml-auto"></i>
          </a>
          <div className={`video-room-sidebar-item-content collapse ${collapseOpened ? 'show' : ''}`} id="collapse4">
            {this.state.warnings.length > 0 && this.renderWarnings()}
            {this.state.error && this.renderError()}
            {this.props.isNotary
              ? this.renderNotaryContent(finalDocumentSignatures)
              : this.renderClientContent(finalDocumentSignatures)
            }
          </div>
        </div>
        {this.renderBlockStatusIcon()}
        <EparakstsSetupModal
          isOpen={showEparakstsSetupModal}
          onClose={() => this.setState({ showEparakstsSetupModal: false })}
        />
      </div>
    )
  }
}

export default FinalDocumentBlock
