import React, { useState } from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import { t } from 'i18n'
import { performEparakstsEidSigning } from '../utilityComponents/EparakstsFunctions'
import { EparakstsSetupModal } from '../utilityComponents/EparakstsSetupModal'
import cableConsumer from '../../files/cableConsumer'

const scope = 'components.sign_document_modal'

const Spinner = () => {
  return <span className='loading-indicator'></span>
}

const SignMethodSelection = ({
  loading, signWithSmartId, signWithEparakstsMobile, signWithEparakstsEid
}) => {
  return (
    <div>
      <h5 className='font-weight-light mb-4'>
        {t('choose_signing_method', { scope })}
      </h5>
      {loading && <Spinner />}
      {!loading && <>
        <div>
          <button
            className='btn sign-document-button mt-0 mb-2'
            onClick={signWithSmartId}
          >
            {t('signing_methods.smartid', { scope })}
          </button>
        </div>
        <div>
          <button
            className='btn sign-document-button mt-0 mb-2'
            onClick={signWithEparakstsMobile}
          >
            {t('signing_methods.eparaksts_mobile', { scope })}
          </button>
        </div>
        <div>
          <button
            className='btn sign-document-button mt-0 mb-2'
            onClick={signWithEparakstsEid}
          >
            {t('signing_methods.eparaksts_eid', { scope })}
          </button>
        </div>
      </>}
    </div>
  )
}

const SmartIdSigning = ({ smartIdCode }) => {
  return (
    <>
      <h5 className='font-weight-light mb-4'>{t('sign_with_smartid', { scope })}</h5>
      <p className='mb-0'>{t('smartid_code', { scope })}:</p>
      <h4 className='mb-4'>{smartIdCode}</h4>
      <p>{t('smartid_hint', { scope })}</p>
    </>
  )
}

const ErrorMessage = ({ message }) => {
  if (!message) return null

  return (
    <div className='errors-section'>
      <i className='fa fa-exclamation-circle mr-2' />
      {message}
    </div>
  )
}

const SignDocumentModal = ({ inheritanceClaim, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [smartIdCode, setSmartIdCode] = useState(null)
  const [showEparakstsSetupModal, setShowEparakstsSetupModal] = useState(false)

  const signWithSmartId = () => {
    setError(null)
    setLoading(true)
    axios.post(
      `/inheritance_claims/${inheritanceClaim.id}/sign_with_smartid`
    ).then(({ data }) => {
      setSmartIdCode(data.code)

      cableConsumer.subscriptions.create(
        {
          channel: 'SmartIdResponseChannel',
          resource_type: 'InheritanceClaim',
          resource_id: inheritanceClaim.id
        },
        {
          received(data) {
            setLoading(false)

            cableConsumer.subscriptions.subscriptions.forEach((subscription) => {
              cableConsumer.subscriptions.remove(subscription)
            })

            if (data.document_signed === true) {
              window.location = `/inheritance_claims/${inheritanceClaim.id}`
            } else {
              setSmartIdCode(null)
              setError(data.error)
            }
          }
        }
      )
    }).catch(({ response }) => {
      const error = t(`components.project.sidebar_steps.signing_block.smartid_statuses.${response.data.error}`, { default: response.error })
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const signWithEparakstsMobile = () => {
    setError(null)
    setLoading(true)
    cableConsumer.subscriptions.create(
      {
        channel: 'EparakstsResponseChannel',
        resource_type: 'InheritanceClaim',
        resource_id: inheritanceClaim.id
      },
      {
        received(data) {
          setLoading(false)

          cableConsumer.subscriptions.subscriptions.forEach((subscription) => {
            cableConsumer.subscriptions.remove(subscription)
          })

          if (data.document_signed === true) {
            window.location = `/inheritance_claims/${inheritanceClaim.id}`
          } else {
            setError(data.error)
          }
        }
      }
    )

    window.open(
      `/inheritance_claims/${inheritanceClaim.id}/sign_with_eparaksts_mobile`,
      '',
      `width=${screen.width},height=${screen.height}`
    )
  }

  const onEparakstsEidStart = () => {
    setError(null)
    setLoading(true)
  }

  const onEparakstsEidError = (error) => {
    setLoading(false)
    setError(error)
  }

  const onEparakstsEidSuccess = () => {
    window.location = `/inheritance_claims/${inheritanceClaim.id}`
  }

  const onEparakstsEidMissingPlugin = () => {
    setLoading(false)
    setShowEparakstsSetupModal(true)
  }

  const signWithEparakstsEid = async () => {
    performEparakstsEidSigning({
      initializeSigningUrl: `/inheritance_claims/${inheritanceClaim.id}/initialize_eid_signing`,
      finalizeSigningUrl: `/inheritance_claims/${inheritanceClaim.id}/finalize_eid_signing`,
      onStart: onEparakstsEidStart,
      onError: onEparakstsEidError,
      onSuccess: onEparakstsEidSuccess,
      onMissingPlugin: onEparakstsEidMissingPlugin
    })
  }

  const smartIdInProgress = !!smartIdCode

  return (
    <Modal className='modal-small sign-document-modal' isOpen={true}>
      <span className='modal-close' onClick={onClose}>
        <i className="fa fa-close fa-lg"></i>
      </span>
      {error && <ErrorMessage message={error} />}
      {!smartIdInProgress &&
        <SignMethodSelection
          loading={loading}
          signWithSmartId={signWithSmartId}
          signWithEparakstsMobile={signWithEparakstsMobile}
          signWithEparakstsEid={signWithEparakstsEid}
        />
      }
      {smartIdInProgress &&
        <SmartIdSigning smartIdCode={smartIdCode} />
      }
      <EparakstsSetupModal
        isOpen={showEparakstsSetupModal}
        onClose={() => setShowEparakstsSetupModal(false)}
      />
    </Modal>
  )
}

export { SignDocumentModal }
